@import 'styles/color.scss';
@import 'styles/font.scss';
@import 'styles/other.scss';

.cases-studies-list {
	position: relative;
	padding-top: 131px;
	padding-bottom: 160px;
	.double-circle-container-top {
		top: 0;
		left: 50%;
		transform: translate(-50%, 0);
	}

	.double-circle-container-bottom {
		bottom: 0;
		right: 0;
		transform: translate(50%, 0);
	}

	.filters-container {
		display: flex;
		width: 50%;
		.filter {
			width: calc(50% - 32px);
			margin-right: 32px;
		}
	}

	.cases-studies-list-container {
		display: flex;
		flex-wrap: wrap;
		position: relative;
		left: -25px;
		width: calc(100% + 50px);
		.case-study-super-container {
			width: 33.333%;
			position: relative;
			.blur-circle-container {
				display: none;
				top: 75%;
				left: 25%;
				transform: translate(-50%, -50%);
			}

			&:nth-child(8n+3) {
				.blur-circle-container {
					display: block;
				}
			}

			.case-study-container {
				margin: 50px 25px 50px 25px;
				.picture {
					height: 330px;
					width: 100%;
					background-size: contain;
					background-color: #fff;
					background-repeat: no-repeat;
					background-position: center;
					filter: drop-shadow(0px 25px 55px rgba(0, 0, 0, 0.15));
					margin-bottom: 27px;
					border-radius: 8px;
				}

				.tag-list {
					margin-bottom: 23px;
					display: flex;
					flex-wrap: wrap;
					.tag {
						margin-right: 16px;
						margin-top: 5px;
						color: $color-7;
						@include text-3(700);
					}
				}

				.title {
					@include title-5;
					margin-bottom: 23px;
				}

				.description {
					@include text-2;
					margin-bottom: 23px;
				}
			}
		}
	}

	.load-more-btn {
		padding-top: 120px;
		text-align: center;
	}

	@media (max-width:$media-query-phone) {
		padding-top: 59px;
		padding-bottom: 100px;
		.filters-container {
			flex-direction: column;
			width: 100%;
			.filter {
				width: 100%;
				margin-right: 0;
			}
		}

		.cases-studies-list-container {
			flex-direction: column;
			left: 0;
			width: 100%;
			.case-study-super-container {
				width: 100%;
				.case-study-container {
					margin: 0px 0px 32px 0px;
					.picture {
						height: 205px;
						width: 100%;
						margin-bottom: 16px;
					}

					.tag-list {
						margin-bottom: 16px;
						.tag {
							margin-right: 8px;
						}
					}

					.title {
						margin-bottom: 16px;
					}

					.description {
						margin-bottom: 16px;
					}
				}
			}
		}

		.load-more-btn {
			padding-top: 70px;
			text-align: center;
		}
	}
}