@import 'styles/color.scss';
@import 'styles/font.scss';
@import 'styles/other.scss';

.images-title{
	@include title-5;
	color: $color-3;
}

.case-study-picture-list {
	display: flex;
	flex-wrap: wrap;
	position: relative;
	left: -16px;
	right: -16px;
	width: calc(100% + 32px);
	padding: 0 0 88px 0;
	.picture-container {
		width: 50%;
		.picture {
			margin: 16px;
			height: 406px;
			width: calc(100% - 32px);
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
		}
	}

	@media (max-width:$media-query-phone) {
		.picture-container {
			width: 100%;
			height: 240px;
			.picture {
				margin: 0;
				width: 100%;
			}
		}
	}
}