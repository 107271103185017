@import "styles/color.scss";
@import "styles/font.scss";

.white-paper-form {
	padding-top: 250px;
	padding-bottom: 97px;
	background-color: $color-13;
	overflow: hidden;
	position: relative;
	.blur-circle-top-container {
		top: 450px;
		left: 400px;
		transform: translate(-50%, -50%);
		z-index: 5;
	}

	.blur-circle-bottom-container {
		z-index: 5;
		bottom: 0px;
		right: 200px;
		transform: translate(50%, 50%);
	}

	.double-circle-container {
		z-index: 5;
		right: 40%;
		bottom: -50px;
		transform: translate(50%, 50%);
	}

	.white-paper-form-super-container {
		z-index: 10;
		position: relative;
		display: flex;
		color: $color-3;
		.introduction {
			width: 50%;
			.introduction-container {
				padding-right: 87px;
				.tag {
					@include title-4;
					padding-bottom: 34px;
				}

				.title {
					@include title-8;
					padding-bottom: 34px;
				}

				.description {
					@include text-11(700);
				}
			}
		}

		.white-paper-form-container {
			width: 50%;

			.form {
				padding-bottom: 64px;
				.consent-link {
					color: inherit;
					text-decoration: underline;
					cursor: pointer;
				}

				.form-input-list {
					.form-description {
						@include text-11(700);
						padding-bottom: 30px;
					}
				}

				.validation-btn {
					margin-top: 40px;
				}
			}
		}
	}

	.hidden-link-for-seo {
		display: none;
	}

	@media (max-width: $media-query-phone) {
		padding-top: 150px;
		padding-bottom: 61px;
		.white-paper-form-super-container {
			flex-direction: column;
			.introduction {
				width: 100%;
				.introduction-container {
					padding-right: 0px;
					padding-bottom: 64px;
					.tag {
						opacity: 0.5;
						padding-bottom: 16px;
					}

					.title {
						padding-bottom: 16px;
					}
				}
			}

			.white-paper-form-container {
				width: 100%;
				.form {
					flex-direction: column;
					left: 0px;
					right: 0px;
					.form-item {
						width: 100%;
						padding: 0;
					}
				}
			}
		}
	}
}
