@import "styles/color.scss";
@import "styles/font.scss";
@import "styles/other.scss";

.list-languages {
	.language-item {
		padding-left: 10px;
		@include text-1(700);
		color: inherit;
		&:hover {
			text-decoration: underline;
		}

		&:nth-child(1) {
			padding-left: 0;
		}
	}

	@media (max-width:$media-query-phone) {
		padding-left: 35px;
	}
	
}