@import 'styles/color.scss';
@import 'styles/font.scss';
@import 'styles/fontImport.scss';

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: $color-1;
	@include text-1;
}

.App {
	overflow: hidden;
}

a {
	cursor: pointer;
	text-decoration: none;
}

h1, h2, h3, h4, h5 {
	margin: 0;
}

button {
	background: transparent;
	border: none;
	cursor: pointer;
}

ul {
	margin: 0;
}
