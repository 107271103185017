@import 'styles/color.scss';
@import 'styles/font.scss';

.office-container {
	@include text-1(500);
	padding-bottom: 63px;
	.name {
		padding-bottom: 32px;
	}

	.adress {
		padding-left: 40px;
		padding-bottom: 11px;
		background-repeat: no-repeat;
		background-size: 24px;
		background-position: top left;
		max-width: 180px;
	}

	.phone {
		padding-left: 40px;
		background-repeat: no-repeat;
		background-size: 24px;
		background-position: top left;
		min-height: 21px;
	}

	&:last-child {
		padding-bottom: 0;
	}
}