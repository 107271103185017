//Main colors
$color-1: #333333;
$color-2: #4C5251;
$color-3: #ffffff;
$color-4: #961F50;
$color-5: #D02E71;
$color-6: #1F6256;
$color-7: #00BE8E;
$color-8: #EEB4CC;
$color-9: #6CD0B7;
$color-10: #CCCCCC;
$color-11: #FCF2F6;
$color-12: #F6D5E3;
$color-13: #3D3D3D;
$color-14: #D8F3EE;
$color-15: #DA578D;
$color-16: #8d0c41;
$color-17: #737373;
$color-18: #85D5C1;
$color-19: #f2eded;

//Warning colors
$color-sucess: #28a745;
$color-info: #007bff;
$color-warning: #ffc107;
$color-error: #dc3545;