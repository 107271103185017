@import 'styles/color.scss';
@import 'styles/font.scss';

.get-in-touch {
	position: relative;
	padding: 80px 0px 80px 0px;
	background: linear-gradient(154deg, #961F50 0%, #370C1E 100%);
	color: $color-3;
	overflow: hidden;
	.double-circle {
		position: absolute;
		width: 624px;
		height: 624px;
		&.top {
			top: 30px;
			left: 30px;
			transform: translate(-50%, -50%);
		}

		&.bottom {
			bottom: 30px;
			right: 30px;
			transform: translate(50%, 50%);
		}
	}

	.info {
		text-align: center;
		padding-bottom: 36px;
		.title {
			@include title-6;
			padding-bottom: 28px;
		}

		.sub-title {
			@include title-1;
			padding-bottom: 28px;
		}
	}

	.content {
		display: flex;
		.map {
			width: 60%;
			position: relative;
			img {
				position: absolute;
				right: 37px;
			}
		}

		.form-container {
			z-index: 5;
			width: 40%;
			.validation-btn {
				margin-top: 40px;
			}
		}
	}

	@media (max-width:$media-query-phone) {
		padding: 55px 0px 55px 0px;
		.info {
			.title {
				padding-bottom: 8px;
			}

			.sub-title {
				padding-bottom: 8px;
			}
		}

		.content {
			flex-direction: column-reverse;
			.map {
				position: relative;
				left: -$padding-page-wrapper;
				right: -$padding-page-wrapper;
				width: calc(100% + $padding-page-wrapper + $padding-page-wrapper);
				img {
					position: static;
					width: 100%;
				}
			}

			.form-container {
				width: 100%;
				padding-bottom: 64px;
			}
		}

		.double-circle {
			&.top {
				top: 0px;
				left: 0px;
				transform: translate(-60%, -80%);
			}

			&.bottom {
				bottom: 0;
				right: 0;
				transform: translate(60%, 80%);
			}
		}
	}
}