@import "styles/color.scss";
@import "styles/font.scss";
@import "styles/other.scss";

.all-solution {
	display: flex;
	position: relative;
	padding-top: 170px;
	padding-bottom: 80px;
	.blur-circle-container {
		left: 20%;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	.left-container {
		padding-right: 100px;
		width: calc(45% - 100px);
		padding-top: 60px;
		.title {
			@include title-3;
		}

		.description {
			@include text-2;
			padding-top: 30px;
		}

		.btn-container {
			padding-top: 45px;
		}
	}

	.right-container {
		width: 55%;
		min-height: 530px;
		.all-solutions-container {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			width: 100%;
			gap: 16px;

			.solution {
				width: 100%;
				position: relative;
				cursor: pointer;
				
				&:nth-child(1) {
					.solution-container {
						background-color: $color-4;
						color: $color-3;
						.solution-icon {
							background-color: $color-9;
						}
					}
				}

				&:nth-child(n + 3) {
					top: -100px;
					grid-column-start: 2;
					grid-row-start: 1;
				}

				&:nth-child(n + 4) {
					grid-row-start: 2;
				}

				.solution-container {
					max-width: calc(100% - 80px);
					padding: 40px 30px 40px 30px;
					border-radius: 8px;
					background-color: $color-3;
					box-shadow: 0px 25px 55px 0px rgba(126, 126, 126, 0.15);
					.solution-icon {
						background-color: $color-8;
						border-radius: 50%;
						width: 56px;
						height: 56px;
						position: relative;
						margin-bottom: 25px;
						img {
							position: absolute;
							top: 50%;
							left: 50%;
							width: 32px;
							height: 32px;
							transform: translate(-50%, -50%);
						}
					}

					.solution-title {
						@include title-16;
						padding-bottom: 25px;
					}

					.solution-description {
						@include text-2;
					}
				}
			}
		}
	}

	@media (max-width: $media-query-phone) {
		flex-direction: column;
		padding-top: 41px;
		padding-bottom: 97px;
		.left-container {
			width: 100%;
			padding-right: 0;
			padding-bottom: 32px;
			padding-top: 0;
			.btn-container {
				position: absolute;
				bottom: 50px;
				padding-top: 0;
			}
		}

		.right-container {
			width: 100%;
			.all-solutions-container {
				padding-bottom: 40px;

				.solution {
					&:nth-child(n + 2) {
						align-self: end;
					}
					&:nth-child(n + 4) {
						grid-column-start: 1;
					}
					.solution-container {
						max-width: calc(100% - 26px);
						padding: 22px 13px 22px 13px;
					}
					&:nth-child(n + 3) {
						top: 0;
					}
				}
			}
		}
	}
}
