@import "styles/font.scss";
@import "styles/color.scss";
@import "styles/other.scss";

.check-container {
	padding-top: 50px;
	
	.check-svg {
		display: block;
		margin: 0 auto;
	}
}