@import 'styles/font.scss';
@import 'styles/other.scss';


.cookie-policy-wrapper {
	padding-top: 250px;
	padding-bottom: 100px;

	.placed-cookies{
		margin-bottom: 30px;

		p{
			margin: 0;
		}

		ul, p{
			margin-bottom: 20px;
		}
	}
	
	section{
		width: 60%;
		margin-bottom: 50px;
	}
	
	h2{
		margin-bottom: 20px;
		@include text-9(400);
	}

	li {
		margin-bottom: 10px;
	}

	@media (max-width:$media-query-phone) {
		padding-top: 130px;
		padding-bottom: 50px;
		section {
			width: 100%;
		}
	}
}