@import "styles/color.scss";
@import "styles/font.scss";
@import "styles/other.scss";

.main-points {
	display: flex;
	align-items: center;
	padding-bottom: 172px;
	position: relative;
	.double-circle-container {
		top: 20%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.blur-circle-container {
		bottom: 200px;
		left: 50%;
		transform: translate(-50%, 50%);
	}

	.left-column {
		margin-right: 100px;
		width: calc(30% - 100px);
		.img-container {
			img {
				width: 100%;
				display: block;
				margin-bottom: 47px;
				box-shadow: 0px 25px 55px 0px rgba(0, 0, 0, 0.25);
				border-radius: 8px;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	.right-column {
		width: 70%;
		.main-point {
			padding-bottom: 64px;
			.title {
				@include title-5;
				padding-bottom: 32px;
			}

			.description {
				@include text-2;
				color: $color-2;
			}
		}
	}

	@media (max-width: $media-query-phone) {
		flex-direction: column;
		padding-bottom: 71px;
		.left-column {
			margin-right: 0px;
			width: 100%;
			display: flex;
			padding-bottom: 32px;
			align-items: flex-end;
			.img-container {
				width: calc(50% - 8px);
				&:nth-child(1) {
					margin-right: 8px;
					padding-bottom: 80px;
				}

				&:nth-child(2) {
					margin-left: 8px;
				}
			}
		}

		.right-column {
			width: 100%;
			.main-point {
				padding-bottom: 32px;
				.title {
					padding-bottom: 16px;
				}
			}
		}
	}
}
