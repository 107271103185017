@import 'styles/color.scss';
@import 'styles/font.scss';
@import 'styles/other.scss';

.tailored-solution {
	.tailored-solution-container {
		display: flex;
		.left-column {
			margin-right: 44px;
			width: calc(100% - 44px);
			.title {
				@include title-8;
			}
		}

		.right-column {
			margin-left: 44px;
			width: calc(100% - 44px);
			.description {
				@include text-2;
				padding-bottom: 40px;
			}
		}
	}

	@media (max-width:$media-query-phone) {
		.tailored-solution-container {
			flex-direction: column;
			.left-column {
				margin-right: 0px;
				width: 100%;
				padding-bottom: 42px;
			}
	
			.right-column {
				margin-left: 0px;
				width: 100%;
			}
		}
	}
}