@import 'styles/color.scss';
@import 'styles/font.scss';

.full-width-component {
	width: 100%;
	background-size: cover;
	background-position: top;
	position: relative;
	color: $color-3;
	.overlay {
		z-index: 1;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: linear-gradient(180deg, rgba(130, 71, 95, 0.85) 0%, rgba(55, 12, 30, 0.72) 100%);
		box-shadow: 0px 25px 55px 0px rgba(0, 0, 0, 0.25);
	}

	.full-width-container {
		z-index: 5;
		position: relative;
		padding-top: 88px;
		padding-bottom: 88px;
	}

	@media (max-width:$media-query-phone) {
		.full-width-container {
			padding-top: 80px;
			padding-bottom: 80px;
		}
	}
}