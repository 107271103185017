@import 'styles/color.scss';
@import 'styles/font.scss';

.text-testimonies {
	display: flex;
	.arrow-container {
		width: 64px;
		.arrow {
			margin-top: 44px;
			position: relative;
			width: 100%;
			height: 100%;
			cursor: pointer;
			&:before, &:after {
				content: "";
				transition: 0.5s;
				position: absolute;
				top: calc(50% + 5px);
				width: 16px;
				height: 4px;
				background-color: $color-5;
				transform: translate(0, -50%) rotate(45deg) ;
			}

			&:after {
				top: calc(50% - 5px);
				transform: translate(0, -50%) rotate(-45deg) ;
			}
		}

		&.left-arrow {
			.arrow {
				margin-right: 40px;
				&:before, &:after {
					left: 0;
				}
			}
		}

		&.right-arrow {
			.arrow {
				&:before, &:after {
					right: 0;
				}

				&:before {
					transform: translate(0, -50%) rotate(-45deg) ;
				}

				&:after {
					top: calc(50% - 5px);
					transform: translate(0, -50%) rotate(45deg) ;
				}
			}
		}

		&.innactive {
			.arrow {
				&:before, &:after {
					background-color: $color-10;
				}
			}
		}
	}

	.testimonies-container {
		width: calc(100% - 64px - 64px);
		overflow: hidden;
		padding-top: 44px;
		.carousel {
			display: flex;
			transition: 0.3s;
			.carousel-item {
				flex: 0 0 100%;
				.testimony-container {
					position: relative;
					border-radius: 8px;
					box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.15);
					background-color: $color-11;
					padding: 100px 125px 75px 125px;
					margin: 0 5px 5px 5px;
					text-align: center;
					height: calc(100% - 100px - 75px - 5px);
					.avatar {
						position: absolute;
						background-size: cover;
						width: 88px;
						height: 88px;
						border-radius: 50%;
						left: 50%;
						top: 0;
						transform: translate(-50%, -50%);
					}
		
					.content {
						padding-bottom: 33px;
						@include text-2;
					}
		
					.author {
						@include text-6(700);
						padding-bottom: 10px;
					}
		
					.role {
						@include text-3(500);
						color: $color-4;
					}
				}
			}
		}
	}

	&.dark-bg {
		.testimonies-container {
			.carousel {
				.carousel-item {
					.testimony-container {
						background-color: $color-1;
						color: $color-3;
						.role {
							@include text-3(500);
							color: $color-3;
						}
					}
				}
			}
		}

		.arrow-container {
			margin-top: calc(25% - 44px);
			width: 56px;
			height: 56px;
			border-radius: 8px;
			background-color: $color-7;
			position: relative;
			cursor: pointer;
			.arrow {
				position: absolute;
				top: -2px;
				left: 50%;
				display: block;
				margin: 30px auto;
				width: 5px;
				height: 5px;
				border-top: 2px solid $color-3;
				border-left: 2px solid $color-3;
				&:after {
					content: "";
					display: block;
					width: 2px;
					height: 10px;
					background-color: $color-3;
					transform: rotate(-45deg) translate(3px, 1px);
					left: 0;
					top: 0;
				}

				&:before {
					background-color: transparent;
				}
			}

			&.left-arrow {
				left: -76px;
				.arrow {
					transform: translate(-50%, -50%) rotate(-45deg);
				}
			}

			&.right-arrow {
				right: -76px;
				.arrow {
					transform: translate(-50%, -50%) rotate(135deg);
				}
			}

			&.innactive {
				background-color: $color-2;
				&.left-arrow, &.right-arrow  {
					.arrow {
						border-color: $color-13;
						&:after{
							background-color: $color-13;
						}
					}
				}
			}
		}
	}

	@media (max-width:$media-query-phone) {
		.arrow-container {
			width: 10px;
		}

		.testimonies-container {
			width: calc(100% - 10px - 10px);
			.carousel {
				.carousel-item {
					.testimony-container {
						padding: 100px 39px 75px 39px;
					}
				}
			}
		}

		&.dark-bg {
			position: relative;

			.testimonies-container {
				width: 100%;
			}

			.arrow-container {
				position: absolute;
				top: calc(50% - 22px);
				transform: translate(0, -50%);
				z-index: 5;
				.arrow {
					position: absolute;
					top: -2px;
					left: 50%;
					display: block;
					margin: 30px auto;
					width: 5px;
					height: 5px;
					border-top: 2px solid $color-3;
					border-left: 2px solid $color-3;
				}
	
				&.left-arrow {
					left: -15px;
				}
	
				&.right-arrow {
					right: -15px;
				}
			}
		}
	}
}