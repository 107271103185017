@import 'styles/font.scss';
@import 'styles/other.scss';

.intro-title {
	font-family: 'Raleway';
	font-size: 48px;
	font-weight: 700;

	@media (max-width:$media-query-phone){
		font-family: 'Karla';
		font-size: 32px;
	}
}

.intro-description {
	@include title-12;
}

.about-us-testimonies-container{
	padding-bottom: 58px;
	display: none;

	&.show{
		display: block;
	}
}