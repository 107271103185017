@import 'styles/color.scss';
@import 'styles/font.scss';
@import 'styles/other.scss';

.clients-list {
	.content {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		left: -35px;
		width: calc(100% + 35px + 35px);
		padding-bottom: 66px;
		.client-container {
			width: calc(25% - 70px);
			padding: 35px;
			.client-bg {
				box-shadow: 0px 15px 35px 0px rgba(0, 0, 0, 0.15);
				border-radius: 8px;
				width: 100%;
				padding-top: 100%;
				margin: auto;
				background-size: contain;
				background-position: center;
				background-repeat: no-repeat;
			}

			&:nth-last-child(-n+4) {
				padding-bottom: 0;
			}
		}
	}

	@media (max-width:$media-query-phone) {
		.content {
			left: -7px;
			width: calc(100% + 7px + 7px);
			padding-bottom: 32px;
			.client-container {
				width: calc(33.333% - 14px);
				padding: 7px;
				&:nth-last-child(4) {
					padding-bottom: 7px;
				}
			}
		}
	}
}