
.checkbox-container{
	margin-top: 10px;
	
	input{
		margin: 0;
	}
	
	label{
		margin-left: 10px;
	}
}