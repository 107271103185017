@import 'styles/color.scss';
@import 'styles/font.scss';
@import 'styles/other.scss';

.list-insight {
	position: relative;
	padding-top: 117px;
	.double-circle-container-top {
		top: -50px;
		left: 25%;
		transform: translate(-50%, 0);
	}

	.double-circle-container-bottom {
		bottom: 300px;
		right: 400px;
		transform: translate(50%, 50%);
	}

	.blur-circle-container-top {
		top: 0;
		right: 0;
		transform: translate(10%, -20%);
	}

	.blur-circle-container-bottom{
		bottom: 0;
		left: 0;
		transform: translate(-10%, 30%);
	}

	@media (max-width:$media-query-phone) {
		padding-top: 75px;
	}
}