@import "styles/color.scss";
@import "styles/font.scss";
@import "styles/other.scss";

.insight-banner {
	padding: 95px;
	padding-top: 250px;
	padding-bottom: 65px;
	.tag {
		padding-bottom: 38px;
		.type-list {
			@include text-8(700);
			padding-right: 16px;
			.type {
				&:after {
					content: ", ";
				}

				&:last-child {
					&:after {
						content: "";
					}
				}
			}
		}

		.date {
			@include text-8(500);
		}
	}

	.title {
		@include title-8;
		padding-bottom: 56px;
	}

	.banner-container {
		width: 100%;
		position: relative;
		display: flex;
		.share-container {
			margin-right: 20px;
			margin-top: auto;
		}

		.picture {
			width: 100%;
			height: 600px;
			background-size: cover;
			background-position: center;
			border-radius: 8px;
			filter: drop-shadow(0px 25px 55px rgba(0, 0, 0, 0.25));
		}
	}

	@media (max-width: $media-query-phone) {
		padding: 30px;
		padding-top: 140px;
		padding-bottom: 32px;
		.tag {
			padding-bottom: 32px;
		}

		.title {
			padding-bottom: 64px;
		}

		.banner-container {
			flex-direction: column-reverse;
			.share-container {
				padding-top: 32px;
			}

			.picture {
				position: relative;
				border-radius: 0;
				left: -$padding-page-wrapper;
				width: calc(100% + $padding-page-wrapper + $padding-page-wrapper);
			}
		}
	}
}
