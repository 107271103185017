@import 'styles/color.scss';
@import 'styles/font.scss';

.main-insights {
	display: flex;
	padding-bottom: 87px;
	.first-insight {
		width: 60%;
	}

	.other-insights {
		width: calc(40% - 31px);
		padding-left: 31px;
		display: flex;
		flex-direction: column;
		.item-container {
			padding-bottom: 30px;
			&:last-child {
				padding-bottom: 0;
			}
		}
	}

	@media (max-width:$media-query-phone) {
		padding-bottom: 32px;
		.first-insight {
			width: 100%;
		}
	
		.other-insights {
			display: none;
		}
	}
}