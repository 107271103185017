@import "styles/color.scss";
@import "styles/font.scss";
@import "styles/other.scss";

.expertise {
	padding: 0 0 87px 0;
	position: relative;
	min-height: 837px;
	.expertise-container {
		.content-container {
			width: calc(50% - 50px);
			.tag {
				@include text-2(700);
				color: $color-2;
				padding-bottom: 32px;
			}

			.title {
				@include title-3;
				padding-bottom: 24px;
			}

			.description {
				@include text-2;
				color: $color-2;
				padding-bottom: 64px;
			}
		}
	}

	.picture-container {
		width: 50%;
		height: 837px;
		position: absolute;
		right: 0;
		top: 0;
		img {
			margin-left: auto;
			display: block;
			border-radius: 8px;
			height: 100%;
		}
	}

	@media (max-width: $media-query-phone) {
		display: flex;
		flex-direction: column-reverse;
		.project-management-expertise-container {
			.content-container {
				width: 100%;
				.tag {
					padding-bottom: 8px;
				}

				.title {
					padding-bottom: 32px;
				}

				.description {
					padding-bottom: 32px;
				}
			}
		}

		.picture-container {
			width: 100%;
			height: 400px;
			position: relative;
			margin-bottom: 32px;
			overflow: hidden;
			img {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: calc(100% + 2px);
			}
		}
	}
}
