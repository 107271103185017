@import 'styles/color.scss';
@import 'styles/font.scss';
@import 'styles/other.scss';

.partnership {
	padding-bottom: 174px;
	position: relative;
	.partnership-container {
		display: flex;
		z-index: 8;
		position: relative;
		.introduction {
			width: 35%;
			.introduction-container {
				padding-right: 90px;
				.tag {
					@include text-3(700);
					padding-bottom: 24px;
				}
	
				.title {
					@include title-8;
					padding-bottom: 24px;
				}
	
				.description {
					@include text-2;
					padding-bottom: 24px;
				}
			}
		}

		.picture-container {
			width: 65%;
			margin-top: 85px;
			.front-picture {
				border-radius: 8px;
				min-height: 100%;
				box-shadow: 0px 25px 55px 0px rgba(0, 0, 0, 0.25);
			}
		}
	}

	.behind-picture {
		position: absolute;
		z-index: 5;
		top: 0;
		right: 0;
		height: 470px;
	}

	@media (max-width:$media-query-phone) {
		padding-bottom: 80px;
		.partnership-container {
			flex-direction: column-reverse;
			.introduction {
				width: 100%;
				.introduction-container {
					padding-right: 0px;
					.tag {
						padding-bottom: 8px;
					}
		
					.title {
						padding-bottom: 32px;
					}
		
					.description {
						padding-bottom: 32px;
					}
				}
			}
	
			.picture-container {
				width: 100%;
				margin-top: 85px;
				padding-bottom: 32px;
				position: relative;
				left: -$padding-page-wrapper;
				.front-picture {
					border-radius: 0px;
					min-height: auto;
					height: 341px;
				}
			}
		}

		.behind-picture {
			position: absolute;
			z-index: 5;
			top: 0;
			right: auto;
			left: $padding-page-wrapper;
			height: 314px;
		}
	
	}
}