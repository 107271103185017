@import 'styles/color.scss';
@import 'styles/font.scss';

.white-paper-get-started {
	display: flex;
	align-items: center;
	.left-column {
		width: 50%;
		.title {
			@include title-8;
			padding-bottom: 26px;
		}

		.description {
			@include text-2;
			padding-bottom: 42px;
		}
	}

	.right-column {
		width: 50%;
		.team-image {
			margin: auto;
			max-width: 100%;
			width: 320px;
			height: 320px;
			background-size: cover;
			border-radius: 50%;
		}
	}

	@media (max-width:$media-query-phone) {
		flex-direction: column-reverse;
		.left-column {
			width: 100%;
			.title {
				@include title-8;
				padding-bottom: 26px;
			}
	
			.description {
				@include text-2;
				padding-bottom: 42px;
			}
		}
	
		.right-column {
			width: 100%;
			margin-bottom: 36px;
		}
	}
}