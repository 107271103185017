@import 'styles/color.scss';

.scroll-to-top-btn{
	display: none;
	position: fixed;
	cursor: pointer;
	width: 48px;
	height:48px;
	z-index: 50;
	bottom: 50px;
	border-radius: 10px;
	background-color: $color-7;
	box-shadow: 0px 4px 15px 2px $color-18;

	.arrow {
		position: relative;
		width: 48px;
		height: 48px;

		&:before, &:after {
			content: "";
			transition: 0.5s;
			position: absolute;
			right: calc( 50% - 3.7px );
			top: 50%;
			width: 12px;
			height: 2px;
			background-color: $color-3;
			transform: translate(50%, -50%) rotate(45deg) ;
		}

		&:after {
			right: calc( 50% + 3.7px);
			transform: translate(50%, -50%) rotate(-45deg) ;
		}
	}
	
	&.show{
		display: block;
	}
}