// Import Karla font
@font-face {
	font-family: 'Karla';
	font-style: normal;
	src: url('./fonts/Karla/Karla-VariableFont_wght.ttf') format('truetype');
}

@font-face {
	font-family: 'Karla';
	font-style: italic;
	src: url('./fonts/Karla/Karla-Italic-VariableFont_wght.ttf') format('truetype');
}

// Import Raleway font
@font-face {
	font-family: 'Raleway';
	font-style: normal;
	src: url('./fonts/Raleway/Raleway-VariableFont_wght.ttf') format('truetype');
}

@font-face {
	font-family: 'Raleway';
	font-style: italic;
	src: url('./fonts/Raleway/Raleway-Italic-VariableFont_wght.ttf') format('truetype');
}

// Import Roboto font
@font-face {
	font-family: 'Roboto';
	font-weight: 100;
	font-style: normal;
	src: url('./fonts/Roboto/Roboto-Thin.ttf') format('truetype');
}

@font-face {
	font-family: 'Roboto';
	font-weight: 100;
	font-style: italic;
	src: url('./fonts/Roboto/Roboto-ThinItalic.ttf') format('truetype');
}

@font-face {
	font-family: 'Roboto';
	font-weight: 300;
	font-style: normal;
	src: url('./fonts/Roboto/Roboto-Light.ttf') format('truetype');
}

@font-face {
	font-family: 'Roboto';
	font-weight: 300;
	font-style: italic;
	src: url('./fonts/Roboto/Roboto-LightItalic.ttf') format('truetype');
}

@font-face {
	font-family: 'Roboto';
	font-weight: 400;
	font-style: normal;
	src: url('./fonts/Roboto/Roboto-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'Roboto';
	font-weight: 400;
	font-style: italic;
	src: url('./fonts/Roboto/Roboto-Italic.ttf') format('truetype');
}

@font-face {
	font-family: 'Roboto';
	font-weight: 500;
	font-style: normal;
	src: url('./fonts/Roboto/Roboto-Medium.ttf') format('truetype');
}

@font-face {
	font-family: 'Roboto';
	font-weight: 500;
	font-style: italic;
	src: url('./fonts/Roboto/Roboto-MediumItalic.ttf') format('truetype');
}

@font-face {
	font-family: 'Roboto';
	font-weight: 700;
	font-style: normal;
	src: url('./fonts/Roboto/Roboto-Bold.ttf') format('truetype');
}

@font-face {
	font-family: 'Roboto';
	font-weight: 700;
	font-style: italic;
	src: url('./fonts/Roboto/Roboto-BoldItalic.ttf') format('truetype');
}

@font-face {
	font-family: 'Roboto';
	font-weight: 900;
	font-style: normal;
	src: url('./fonts/Roboto/Roboto-Black.ttf') format('truetype');
}

@font-face {
	font-family: 'Roboto';
	font-weight: 900;
	font-style: italic;
	src: url('./fonts/Roboto/Roboto-BlackItalic.ttf') format('truetype');
}