@import 'styles/color.scss';
@import 'styles/font.scss';

.numbers {
	padding-top: 125px;
	padding-bottom: 100px;
	background-size: contain;
	background-repeat: no-repeat;

	&.withBackgroundColor{
		background-color: $color-11;
	}
	.numbers-super-container {
		.company-logo {
			padding-bottom: 20px;
			margin: auto;
			text-align: center;
			img {
				max-width: 100%;
			}
		}

		.title {
			@include title-3;
			padding-bottom: 65px;
			text-align: center;
		}

		.numbers-container {
			display: flex;
			flex-wrap: wrap;
			position: relative;
			left: -18px;
			right: -18px;
			width: calc(100% + 36px);
			.number-item-super-container {
				width: calc(33.333% - 36px);
				padding-left: 18px;
				padding-right: 18px;
				padding-bottom: 60px;
				display: flex;
				&:nth-child(3n+2) {
					justify-content: center;
					.number-item-container {
						text-align: center;
					}
				}

				&:nth-child(3n+3) {
					justify-content: right;
					.number-item-container {
						text-align: right;
					}
				}

				&:nth-last-child(-n+3) {
					padding-bottom: 0;
				}

				.number-item-container {
					.number {
						@include number-1;
					}
				}
			}
		}
	}

	@media (max-width:$media-query-phone) {
		padding-top: 0;
		background-size: cover;
		.numbers-super-container {
			.company-logo {
				width: 79px;
				padding-bottom: 13px;
			}

			.title {
				padding-bottom: 47px;
			}

			.numbers-container {
				.number-item-super-container {
					width: calc(50% - 36px);
					padding-bottom: 24px;
					&:nth-child(3n+2) {
						justify-content: flex-start;
						.number-item-container {
							text-align: left;
						}
					}
		
					&:nth-child(3n+3) {
						justify-content: flex-start;
						.number-item-container {
							text-align: left;
						}
					}
				}
			}
		}
	}
}