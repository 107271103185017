@import 'styles/color.scss';
@import 'styles/font.scss';
@import 'styles/other.scss';

.ceo {
	padding-bottom: 110px;
	text-align: center;
	margin-top: -20px;
	position: relative;
	.double-circle-container {
		top: 30%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.blur-circle-container {
		top: 75%;
		left: 50%;
		transform: translate(-50%,-50%);
	}

	.ceo-picture-container {
		padding-bottom: 64px;
		img {
			width: 466px;
			max-width: 100%;
		}
	}

	.tag {
		@include text-16(700);
		color: $color-2;
		padding-bottom: 32px;
	}

	.name {
		@include title-15;
		padding-bottom: 24px;
	}

	.description {
		@include text-16;
	}

	@media (max-width:$media-query-phone) {
		padding-bottom: 74px;
		padding-top: 70px;
		margin-top: 0;
	}
}