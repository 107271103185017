@import 'styles/color.scss';
@import 'styles/font.scss';

.contact-us-introduction {
	position: relative;
	padding-top: 250px;
	padding-bottom: 112px;
	text-align: center;
	.blur-circle-container {
		top: calc(50% + 79px);
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.tag {
		@include text-2(700);
		color: $color-2;
		padding-bottom: 32px;
	}

	.title {
		@include title-8;
		padding-bottom: 24px;
	}

	.description {
		@include text-2;
	}

	@media (max-width:$media-query-phone) {
		padding-top: 150px;
		padding-bottom: 53px;
		.tag {
			padding-bottom: 8px;
		}

		.title {
			padding-bottom: 32px;
		}
	}
}