@import "styles/font.scss";
@import "styles/color.scss";
@import "styles/other.scss";

.notice-container{
	position: relative;
	padding-top: 250px;
	text-align: center;
	height: calc(100vh - $height-main-nav - 160px);
	
	p{
		margin: 0;
	}
	
	.tag-container{
		@include text-12(700);
	}
	
	.title-container {
		@include title-15;
		padding-top: 32px;
	}
	
	.subtitle-container {
		@include text-16;
		color: $color-2;
		padding-top: 24px;
	}
	
	@media (max-width:$media-query-phone) {
		height: calc(100vh - $height-main-nav - 60px);
		padding-top: 150px;
	}
}