@import 'styles/color.scss';
@import 'styles/font.scss';
@import 'styles/other.scss';

.event-register {
	background: linear-gradient(180deg, #961F50 0%, #370C1E 100%);
	box-shadow: 0px 25px 55px 0px rgba(0, 0, 0, 0.25) inset;
	padding: 144px 0 182px 0;
	.event-register-container {
		.title {
			@include title-11;
			color: $color-3;
			text-align: center;
			padding-bottom: 122px;
		}

		.iframe-container {
			background-color: $color-12;
			border-radius: 8px;
			opacity: 0.8;
			padding: 25px;
			iframe {
				width: 100%;
				height: 1142px;
			}
		}
	}
}