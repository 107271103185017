@import "styles/font.scss";
@import "styles/color.scss";

.event-banner {
	height: 100vh;
	background-size: cover;
	background-position: center;
	.bg-gradiant {
		height: 100%;
		background: linear-gradient(82deg, rgba(0, 0, 0, 0.80) 0%, rgba(0, 0, 0, 0.24) 59.80%, rgba(149, 28, 78, 0.00) 100%);
		.event-banner-super-container {
			position: relative;
			height: 100%;
			.event-banner-container {
				position: absolute;
				top: 50%;
				transform: translate(0, -50%);
				width: 100%;
				color: $color-3;
				.tag {
					@include text-2(700);
					padding-bottom: 43px;

					span{
						margin-right: 15px;
					}
				}

				.title {
					@include title-7;
					padding-bottom: 43px;
				}
		
				.details {
					@include text-9(700);
					padding-bottom: 43px;
					.detail-item {
						padding-bottom: 24px;
						&:last-child {
							padding-bottom: 0;
						}
					}
				}

				.action-list {
					.btn-container {
						margin-right: 18px;
						display: inline-block;
						position: relative;
						.share-options-container {
							display: none;
							background-color: #CCCCCC;
							border-radius: 8px;
							padding: 10px 15px;
							position: absolute;
							top: 100%;
							margin-top: 20px;
							&.open{
								display: block;
							}
						}
					}
				}
			}
		}
	}

	@media (max-width:$media-query-phone) {
		.bg-gradiant {
			background: linear-gradient(180deg, rgba(0, 0, 0, 0.80) 0%, rgba(0, 0, 0, 0.24) 59.80%, rgba(149, 28, 78, 0.00) 100%);

			.event-banner-super-container {

				.event-banner-container {
	
					.action-list {
						display: flex;
						flex-direction: column;
						
						.btn-container {
							.btn {
								margin-bottom: 16px;
							}

							.share-options-container {
								margin-top: 60px;
							}
						}
					}
				}
			}
		}
	}
}